// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "@angular/material/theming";
@import "ngx-toastr/toastr.css";
@import "colors.scss";
@import "styles-variables.scss";
@import "@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$wbd-ui-primary: mat-palette($mat-indigo);
$wbd-ui-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$wbd-ui-warn: mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$wbd-ui-theme: mat-light-theme($wbd-ui-primary, $wbd-ui-accent, $wbd-ui-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($wbd-ui-theme);

/* You can add global styles to this file, and also import other style files */
* {
    font-family: $base-font-family !important;
}
.material-icons {
    font-family: "Material Icons", Helvetica, sans-serif !important;
}
.fa,
.fas {
    font-family: "Font Awesome 5 Free", Helvetica, sans-serif !important;
}
html,
body {
    height: 100%;
}
body,
.mat-body,
.mat-body-1,
.mat-typography .mat-body,
.mat-typography .mat-body-1,
.mat-typography {
    margin: 0;
    padding: 0;
    font-size: $base-font-size;
}

/*** Utility Classes ***/
@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.fade-in {
    animation: fadeInAnimation ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

/**** Icons ****/
.icon-style {
    font-size: 16px;
    height: 28px;
    width: 28px;
    cursor: pointer;
    background-color: $white-color;
    color: $edit-icon-color;
    border: 1px solid $edit-icon-color;
    text-align: center;
    line-height: 26px;
    border-radius: 100%;
}

.icon-style-hover {
    color: $edit-icon-hover-color;
    border: 1px solid $edit-icon-hover-color;
}

/******** Overwriting Material Css **********/
.main-container {
    .mat-card {
        border: 1px solid #ccc;
        padding: 0;
        .mat-card-header {
            border-bottom: 1px solid $heading-border-color;
            background-color: $heading-bg-color;
            .mat-card-header-text {
                margin: 0;
                padding: 10px 15px;
            }
            .mat-card-title {
                margin: 0;
                color: $heading-text-color;
                font-size: 18px;
            }
        }
        .mat-card-content {
            padding: 15px;
            .mat-elevation-z8 {
                box-shadow: none;
                border: 1px solid rgba(0, 0, 0, 0.12);
            }
            .mat-list-item-content {
                /** dashboard quick links **/
                display: flex;
                // justify-content: space-between;
                .mat-list-item-ripple {
                    display: none;
                }
            }
        }
        .mat-paginator {
            background-color: $general-purpose-off-white;
            font-weight: 700;
        }
    }
}

.tool-tip-class {
    background-color: $tooltip-bg;
    border: 1px solid $tooltip-border !important;
    color: $tooltip-text-color !important;
    font-size: 14px;
    white-space: pre-line;
    -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.32);
    -moz-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.32);
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.32);
}

.mat-error {
    font-size: 14px;
}

/**** Table ****/
table.mat-table {
    width: 100%;
    tr.mat-header-row {
        background-color: $general-purpose-off-white;
        th {
            font-size: 14px;
            padding: 0 8px;
            font-weight: bold;
            color: $general-purpose-brown;
            .mat-sort-header-button {
                text-transform: capitalize;
            }
            .mat-sort-header-content {
                text-transform: capitalize;
                text-align: left; //All header left align
            }
        }
    }
    tr.mat-row {
        &.formulary-row {
            background-color: $accent-palette-green;
        }
        &.pending-approval-row {
            background-color: $accent-palette-light-red;
        }
        &.highlight-row {
            background-color: $accent-palette-light-red !important;
        }
        &.houseAcc-row {
            background-color: $table-row-houseAccount-hover !important;
        }
        td.mat-cell {
            // font-size: 13px;
            padding: 0 8px;
            .grid-icon {
                @extend .icon-style;
                &:first-child {
                    margin-right: 5px;
                }
                &:hover {
                    @extend .icon-style-hover;
                }
            }
            &.clickable {
                text-decoration: underline;
                color: $link-color;
                &:hover {
                    cursor: pointer;
                    text-decoration: none;
                    color: $link-hover-color;
                }
            }
            &.text-center {
                text-align: center;
            }
            &.text-right {
                text-align: right;
            }
            &.text-left {
                text-align: left;
            }
        }
        &:nth-child(2n + 1) {
            background-color: $general-purpose-lighter-brown;
            &.formulary-row {
                background-color: $accent-palette-green;
            }
            &.pending-approval-row {
                background-color: $accent-palette-light-red;
            }
        }
    }
    &.order-status-table {
        tr {
            td {
                font-size: 14px !important;
            }
        }
    }
}

.table-tooltip {
    font-size: 14px;
}

.no-records {
    padding: 10px;
    text-align: center;
    font-weight: 700;
    color: $active-palette-red;
}

.mat-column-modifiedBy,
.mat-column-tradeName,
.mat-column-genericName {
    span {
        overflow-wrap: break-word;
        width: 120px;
        display: block;
        cursor: pointer;
    }
}

/**** Search ****/
.mat-form-search-cont {
    mat-form-field {
        width: 35%;
        .mat-form-field-wrapper {
            margin-right: 1em;
        }
    }
}

.held-queue-search-bar {
    .mat-hint {
        font-size: 13px;
        font-weight: 700;
    }
    .optional-fields {
        .right {
            .mat-form-field-flex {
                background-color: $white-color;
                .mat-form-field-infix {
                    padding: 5px 0 5px;
                    margin-top: 4px;
                    .dateTimeIcon {
                        margin-top: -12px;
                        cursor: pointer;
                    }
                }
                .mat-input-element {
                    width: 78%;
                }
            }
        }
    }
}

.complianceOrderStatus-search-bar {
    .mat-hint {
        font-size: 13px;
        font-weight: 700;
    }
    .optional-fields {
        .right {
            .mat-form-field-flex {
                background-color: $white-color;
                .mat-form-field-infix {
                    padding: 5px 0 5px;
                    margin-top: 4px;
                    .dateTimeIcon {
                        margin-top: -12px;
                        cursor: pointer;
                    }
                }
                .mat-input-element {
                    width: 78%;
                }
            }
        }
    }
}

.replenishment-order-search {
    .mat-hint {
        font-size: 13px;
        font-weight: 700;
    }
    .optional-fields {
        .left {
            .mat-form-field-flex {
                background-color: transparent;
                .mat-form-field-infix {
                    padding: 5px 0 5px;
                    margin-top: 4px;
                    .dateTimeIcon {
                        margin-top: -12px;
                        cursor: pointer;
                    }
                }
                .mat-input-element {
                    width: 78%;
                }
            }
        }
    }
}

.report-dialog {
    .search-filter {
        margin: 0;
        label {
            font-size: 14px;
        }
        .mat-hint {
            font-size: 13px;
            font-weight: 700;
        }
        form {
            margin: 0 0 15px;
            display: flex;
            padding: 0;
            justify-content: space-between;
            .search-1 {
                width: 22%;
                margin-bottom: -5px;
                label#filter-by {
                    margin: 0 8px 0 0;
                    font-weight: bold;
                }
                .mat-radio-button {
                    margin: 0 15px 0 0;
                    label {
                        margin-bottom: 0;
                    }
                }
                .mat-form-field {
                    width: 90%;
                    .mat-form-field-flex {
                        .mat-form-field-infix {
                            padding: 5px;
                        }
                    }
                }
            }
            .search-2 {
                width: 33%;
                display: flex;
                justify-content: space-between;
                margin-bottom: -15px;
                .field-wrap {
                    width: 50%;
                    .label-txt {
                        display: block;
                        margin-bottom: 0;
                    }
                    .mat-form-field {
                        padding: 0 10px 0 0;
                        width: 90%;
                    }
                }
            }
            .search-3 {
                width: 30%;
                margin-bottom: -15px;
                .label-txt {
                    display: block;
                    margin-bottom: 3px;
                }
                .field-wrap {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: -15px;
                    position: relative;
                    .toDateErr {
                        font-size: 13px;
                        font-weight: 700;
                        clear: both;
                        position: absolute;
                        right: 0;
                        bottom: 0;
                    }
                    .mat-form-field {
                        padding: 0;
                        width: 48%;
                        .mat-form-field-flex {
                            padding: 0 5px;
                            background-color: $white-color;
                            .mat-form-field-infix {
                                padding: 5px 0 6px;
                            }
                        }
                        input.mat-input-element {
                            width: 80%;
                            // float: left;
                        }
                        .dateTimeIcon {
                            // float: right;
                            margin-top: -10px;
                            cursor: pointer;
                        }
                    }
                }
            }
            .btn-wrap {
                // width: 15%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .search {
                    // cursor: pointer;
                    // height: 55px;
                    margin-right: 10px;
                }
            }
        }
    }
    .table-container {
        table {
            border: 1px solid #dee2e6;
            th {
                background-color: $general-purpose-lighter-gray;
                border: 1px solid #dee2e6;
            }
            td {
                background-color: $table-report-bg;
                font-size: 14px;
                border: 1px solid #dee2e6;
            }
        }
    }

    .no-records {
        border: 1px solid #dee2e6;
        margin-bottom: 15px;
        min-width: 90vw;
    }
    .banner {
        .chart {
            font-size: 72px;
            color: $grid-cont-icon-color;
        }
        text-align: center;
        padding-top: 5%;
        height: 200px;
        width: 100%;
        font-size: 18px;
        font-style: italic;
    }

    button {
        cursor: pointer;
    }
}

/**** Modal dialog ****/
.fullScreenDialog {
    max-width: 95vw !important;
    min-width: 65vw;
    // height: 95vh!important;
    // .mat-dialog-content {
    //     height: 85vh;
    // }
}
.mat-dialog-container {
    padding: 0 !important;
    .mat-dialog-title {
        border-bottom: 1px solid $heading-border-color;
        background-color: $heading-bg-color;
        padding: 10px 15px;
        display: flex;
        justify-content: space-between;
        margin: 0 0 10px;
        h4 {
            font-size: 20px;
            margin: 0;
        }
        .dialog-icon {
            @extend .icon-style;
            margin-top: 5px;
            height: 24px;
            width: 24px;
            line-height: 23px;
            &:hover {
                @extend .icon-style-hover;
            }
        }
    }
    .mat-dialog-content {
        padding: 10px 15px 0;
        margin: 0;
        border-bottom: 1px solid $heading-border-color;
        min-height: 50px;
        min-width: 400px;
        ul {
            padding: 0;
            margin: 0;
            list-style: none;
            li {
                display: block;
                margin-bottom: 15px;
                strong {
                    font-weight: 700;
                    margin-right: 5px;
                }
                &:last-child {
                    display: flex;
                    justify-content: space-between;
                    margin: 0;
                }
                .field-wrap {
                    width: 45%;
                    .mat-form-field {
                        width: 100%;
                        // margin-bottom: -8px;
                        .mat-input-element {
                            width: 80%;
                            float: left;
                        }
                        .dateTimeIcon {
                            float: right;
                            margin-top: -10px;
                            cursor: pointer;
                        }
                    }
                    &:last-child {
                        .mat-form-field {
                            float: right;
                            margin-bottom: -10px;
                        }
                    }
                }
                .mat-form-field-appearance-fill {
                    .mat-form-field-subscript-wrapper {
                        padding: 0;
                        line-height: 1.2;
                    }
                }
                .mat-error {
                    margin-top: -15px;
                }
            }
        }
        &.inventory-dialog {
            ul {
                margin-bottom: 15px;
                overflow: hidden;
                li {
                    width: 50%;
                    float: left;
                    justify-content: flex-start;
                    .input-wrap {
                        display: inline-block;
                        &.input-without-label {
                            .mat-form-field-infix {
                                border-top: 0;
                                width: 150px;
                            }
                        }
                    }
                    .mat-form-field {
                        margin-right: 12px;
                    }
                    // &:last-child {
                    //     float:none;
                    //     width: 100%;
                    //     .button.primary {
                    //         margin: 0 auto;
                    //     }
                    // }
                    a {
                        text-decoration: underline;
                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }
        }
        .info-message {
            border: 1px solid $info-message-icon-color;
            padding: 10px;
            display: flex;
            background: #f9f9f9;
            border-radius: 3px;
            margin-bottom: 10px;
            .info-icon {
                color: $info-message-icon-color;
                font-size: 18px;
                margin-right: 8px;
            }
            span {
                color: $info-message-text-color;
                font-size: 14px;
            }
        }
        .dialog-table {
            clear: both;
            margin-bottom: 15px;
            box-shadow: none;
            border: 1px solid rgba(0, 0, 0, 0.12);
            .mat-table {
                tr.mat-row {
                    td.mat-cell {
                        font-size: 14px;
                        text-align: left;
                    }
                }
            }
        }
    }
    .mat-dialog-actions {
        padding: 0 15px;
        margin: 0;
    }
}

/*** Report dialog fix ***/
.report-dialog {
    table {
        th {
            .mat-sort-header-button {
                text-transform: capitalize;
                font-size: 14px;
                font-weight: 700;
            }
        }
    }
    .search-filter {
        .filter-wrap {
            padding: 0;
            .mat-radio-label {
                margin: 0;
            }
        }
        .search-3 {
            padding: 0;
            .field-wrap {
                .mat-form-field {
                    .mat-form-field-flex {
                        background-color: $white-color;
                    }
                    .mat-form-field-infix {
                        padding-bottom: 6px;
                    }
                }
            }
        }
    }
}

/**** Button ****/
button {
    &:focus {
        outline: none;
    }
}

.button.primary {
    background-color: $primary-button-color;
    border-color: $primary-button-color;
    font-size: 15px;
    &:hover {
        background-color: $primary-button-hover-color;
    }
}

.button.primary[disabled] {
    &:hover {
        background-color: rgba(0, 0, 0, 0.12);
    }
}

.mat-stroked-button.mat-primary {
    color: $primary-button-color;
    font-size: 15px;
    &.condensed-btn {
        line-height: 25px;
        padding: 0 5px;
        background-color: $white-color;
    }
}

.tertiary-button {
    background-color: $tertiary-button-color;
    color: $tertiary-button-text-color;
    font-size: 15px;
    &.condensed-btn {
        line-height: 25px;
        padding: 0 5px;
    }
    &:hover {
        background-color: $tertiary-button-hover-color;
    }
}

/******** Mat tab Css ***********/
.mat-tab-group {
    .mat-tab-label {
        .mat-tab-label-content {
            font-weight: 900;
            font-size: 16px;
        }
        &.mat-tab-label-active {
            color: #e41f35;
            opacity: 1;
        }
    }
    &.mat-primary {
        .mat-ink-bar {
            background-color: #e41f35;
        }
    }
    .mat-tab-body {
        border: 1px solid $tab-border-color;
        border-top: none;
        background-color: $tab-bg-color;
        padding: 10px;

        table.mat-table {
            tr.mat-header-row {
                background-color: $white-color;
            }
            tr.mat-row {
                td.mat-cell {
                    font-size: 14px;
                    &:last-child {
                        div.table-btn {
                            text-align: right;
                        }
                    }
                }
            }
        }

        .mat-paginator {
            background-color: $white-color;
        }
    }
}

/******** Overwriting Material Css **********/

/******** grid - box - container ************/
.grid-box-container {
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    justify-content: space-evenly;
    width: 100%;

    .grid-box {
        margin: 0px;
        flex: 1;

        .grid-header {
            background-color: #c8c9c7;
            color: #333;
            text-align: left;
            margin-bottom: 0px;
            height: 25px;
            line-height: 25px;
            font-weight: bold;
            font-size: 13px;
            padding: 0 5px;
        }

        .table-container {
            height: 250px;
            max-height: 300px;
            overflow: auto;
            border: 1px solid #ccc;

            .mat-row {
                cursor: pointer;
            }
        }
    }

    .grid-button-box {
        margin: 25px 10px 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        button {
            display: block;
            width: 80%;
            //min-width:inherit;
            &:not(:first-child) {
                margin-top: 5px;
            }
        }
    }

    &.disabled {
        opacity: 0.7;
        pointer-events: none;
        button.primary {
            background-color: #999;
        }

        .mat-stroked-button.mat-primary {
            color: #999;
        }
        .no-records {
            color: #999;
        }
    }
}
/******** grid - box - container ************/

/******** Expansion panel ************/
.mat-expansion-panel {
    margin-bottom: 3px !important;
    .mat-expansion-panel-header {
        background-color: $panel-header-bg;
        height: 50px !important;
        &:hover,
        &:focus {
            background-color: $panel-header-bg-hover !important;
        }
        .mat-expansion-panel-header-title {
            font-size: 16px;
            font-weight: bold;
        }
    }
    .mat-expansion-panel-body {
        padding: 8px 15px;
        .mat-expansion-panel-header {
            background-color: $panel-header-inner-bg;
            height: 40px !important;
            &:hover,
            &:focus {
                background-color: $panel-header-inner-bg-hover !important;
            }
        }
    }
}
/******** Expansion panel ************/
