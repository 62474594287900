/**** Global Color ****/
$white-color: #ffffff;
$link-color: #0b50cede;
$link-hover-color: #1b0de8de;

/*** body Constants ***/
$background-color: $white-color;
/*** body Constants ***/

/*** Additional Colors ***/
$shadow-grey: #dadada;
$shadow-light-grey: rgba(0, 0, 0, 0.16);
$table-row-hover-color: rgba(243, 239, 4, 0.3);
$table-row-houseAccount-hover-color: rgba(243, 154, 4, 0.3);
$info-message-icon-color: #F9C916;
$info-message-text-color: #555;

/********** Style guide colors ***********/
/****** Active palette *******/
$active-palette-purple: #6e0a54;
$active-palette-red: #E41F35;
$active-palette-yellow: #F9C916;
$active-palette-blue: #346170;
$active-palette-dark-red: #730505;
$active-palette-dark-yellow: #dcae00;

/****** Accent palette *******/
$accent-palette-sky-blue: #92D3E1;
$accent-palette-cyan: #75C5B4;
$accent-palette-green: #9BC787;
$accent-palette-light-green: #D0E276;
$accent-palette-light-red: #FFB3B2;

/****** General purpose colors *******/
$general-purpose-cream: #FEF2C5;
$general-purpose-lighter-brown: #F4F2E6;
$general-purpose-light-brown: #E9E4CD;
$general-purpose-brown: #907736; 
$general-purpose-green: #269c30;
$general-purpose-off-white: #FCFBF7;
$general-purpose-lighter-gray: #F1F1F1;
$general-purpose-light-gray: #C8C8D2;
$general-purpose-dark-gray: #2E2E2F;
$general-purpose-black: #000000;
/********** Style guide colors ***********/

/**** Header colors ****/
$header-bg: $white-color;
$header-border-color: $general-purpose-light-brown;

/**** Navigation colors ****/
$nav-bg-color: $general-purpose-off-white;
$nav-bg-hover-color: $accent-palette-light-green;
$nav-bg-highlighted-color: $accent-palette-light-green;
$nav-divider-color: $general-purpose-light-gray;
$nav-icon-color: $general-purpose-dark-gray;
$nav-icon-hover-color: $active-palette-red;

/**** Icons ****/
$info-icon-color: $active-palette-yellow;
$info-icon-hover-color: $link-color;
$edit-icon-color: $active-palette-red;
$edit-icon-hover-color: $active-palette-dark-red;

/**** Buttons ****/
$primary-button-color: $active-palette-red;
$primary-button-hover-color: $active-palette-dark-red;
$secondary-button-color: #ffffff;
$primary-button-text-color: #ffffff;
$secondary-button-text-color: #e41f32;
$secondary-button-hover-color: #F1F1F1;
$tertiary-button-color: #0fb100;
$tertiary-button-text-color: #ffffff;
$tertiary-button-hover-color: #0e7a04;
$muted-button-color: rgb(235, 235, 235);
/**** Buttons ****/


/**** Table ****/
$table-header-bg: $general-purpose-lighter-gray;
$table-report-bg: $general-purpose-off-white;
$table-row-hover: $table-row-hover-color;
$table-row-houseAccount-hover: $table-row-houseAccount-hover-color;
$table-border-color: $general-purpose-light-gray;

/**** Text colors ****/
$heading-bg-color: $general-purpose-lighter-brown;
$heading-text-color: $general-purpose-dark-gray;
$heading-border-color: $general-purpose-light-gray;

$username-color: $active-palette-blue;
$username-hover-color: $link-color;

$tooltip-bg: $general-purpose-lighter-brown;
$tooltip-border: $general-purpose-brown;
$tooltip-text-color: $general-purpose-dark-gray;

$info-message-icon: $info-message-icon-color;
$info-message-text: $info-message-text-color;

/**** Tab colors ****/
$tab-border-color: $general-purpose-light-gray;
$tab-bg-color:#f9f5e9;

/**** Dashboard colors ****/
$menu-box-border-color:$general-purpose-lighter-gray;
$menu-box-headerbg-color:$general-purpose-lighter-gray;
$menu-box-transition-speed: 0.6s;

$menu-box-held-queue-color:$active-palette-purple;
$menu-box-held-queue-hover-color:$active-palette-purple;
$menu-box-held-queue-border-color:$active-palette-purple;

$menu-box-inventory-color:$active-palette-red;
$menu-box-inventory-hover-color:$active-palette-red;
$menu-box-inventory-border-color:$active-palette-red;

$menu-box-formulary-color:$active-palette-blue;
$menu-box-formulary-hover-color:$active-palette-blue;
$menu-box-formulary-border-color:$active-palette-blue;

$menu-box-reports-color:$active-palette-dark-red;
$menu-box-reports-hover-color:$active-palette-dark-red;
$menu-box-reports-border-color:$active-palette-dark-red;

$menu-box-order-status-color:$general-purpose-green;
$menu-box-order-status-hover-color:$general-purpose-green;
$menu-box-order-status-border-color:$general-purpose-green;

$menu-box-order-import-color:$active-palette-dark-yellow;
$menu-box-order-import-hover-color:$active-palette-dark-yellow;
$menu-box-order-import-border-color:$active-palette-dark-yellow;

$quick-links-color: $link-color;
$quick-links-bg-color: $general-purpose-lighter-gray;

/**** Grid Thumbnail colors ****/
$grid-box-transition-speed: 0.4s;
$grid-cont-bg-color: $general-purpose-lighter-gray;
$grid-cont-bg-color-hover: #ecffe4;
$grid-cont-border-color: #ddd ;
$grid-cont-border-color-hover:  #0fb100;
$grid-cont-text-color: $general-purpose-dark-gray;
$grid-cont-text-color-hover: #18743b;
$grid-cont-icon-color:$active-palette-blue;
$grid-cont-icon-color-hover: #0fb100;
$grid-preview-icon-bg : #0fb100;
$grid-preview-icon-color: $white-color;

/**** Accordion/Expansion panel colors ****/
$panel-header-bg: #dcface;
$panel-header-bg-hover: rgba(243, 239, 4, 0.3);

$panel-header-inner-bg: #d8f9fa;
$panel-header-inner-bg-hover:#bbedee;