@media print {
  .wrapper,
  .cdk-overlay-container {
    display: none;
  }
  .print-view {
    display: block;
  }
  table {
    table {
      th {
        text-transform: capitalize;
        border: 1px solid #ddd;
      }
      td {
        border: 1px solid #ddd;
      }
    }
  }
}
